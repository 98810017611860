import axios from 'axios'
import type { AxiosResponse } from 'axios'
;(function () {
  function initializeExport() {
    const button = document.getElementById('export-menu-button') as HTMLButtonElement
    const menu = document.getElementById('export-menu') as HTMLDivElement
    const exportLink = document.getElementById('export-xlsx-link') as HTMLAnchorElement
    const loadingIndicator = document.getElementById('export-loading') as HTMLDivElement
    const exportError = document.getElementById('export-error') as HTMLDivElement

    if (!button || !menu || !exportLink || !loadingIndicator || !exportError) {
      console.error('One or more export elements not found')
      return
    }

    button.addEventListener('click', function () {
      menu.classList.toggle('hidden')
    })

    document.addEventListener('click', function (event) {
      if (!button.contains(event.target as Node) && !menu.contains(event.target as Node)) {
        menu.classList.add('hidden')
      }
    })

    function handleExportError(error: Error) {
      console.error('Export error:', error)
      exportError.classList.remove('hidden')
    }

    exportLink.addEventListener('click', function (this: HTMLAnchorElement, event: MouseEvent) {
      event.preventDefault()
      loadingIndicator.classList.remove('hidden')
      exportError.classList.add('hidden')

      axios({
        url: this.href,
        method: 'GET',
        responseType: 'blob',
        headers: {
          Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        },
      })
        .then((response: AxiosResponse<Blob>) => {
          if (response.status === 200) {
            return response.data
          } else {
            throw new Error('Export failed')
          }
        })
        .then((blob: Blob) => {
          const url = window.URL.createObjectURL(blob)
          const a = document.createElement('a')
          a.style.display = 'none'
          a.href = url
          a.download = `deals_export_${new Date().toISOString().slice(0, 19).replace(/[-:]/g, '')}.xlsx`
          document.body.appendChild(a)
          a.click()
          window.URL.revokeObjectURL(url)
        })
        .catch((error) => {
          handleExportError(error)
        })
        .finally(() => {
          loadingIndicator.classList.add('hidden')
        })
    })
  }

  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', initializeExport)
  } else {
    initializeExport()
  }
})()
