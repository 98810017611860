import '~/application.tailwind.css'
import '~/styles/deal_form.tailwind.css'
import Questionnaire from '~/questionnaire'
import '~/export/index.ts'

const Ypog = {
  Questionnaire,
}

window.Ypog = Ypog
